<template>
  <div class="all">
    <canvas id="canvas1" width="250" height="250" v-show="false"></canvas>
    <canvas id="canvas2" width="250" height="250" v-show="false"></canvas>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <div class="search-group-wrap">
          <div class="search-group-dec">个人查找：</div>
          <div class="search-group">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="姓名：">
                  <el-input
                    v-model="searchForm.username"
                    placeholder="请输入姓名"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="订单编号：">
                  <el-input
                    v-model="searchForm.billNo"
                    placeholder="请输入订单编号"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="手机号：">
                  <el-input
                    v-model="searchForm.mobile"
                    placeholder="请输入手机号"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="身份证号：">
                  <el-input
                    v-model="searchForm.idCardNo"
                    placeholder="请输入身份证号"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="search-group-wrap">
          <div class="search-group-dec">订单筛选：</div>
          <div class="search-group">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="申请日期：">
                  <el-date-picker
                    style="width: 100%"
                    v-model="application_date"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="setDateRange"
                    @change="onApplicationDateChange"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="放款日期：">
                  <el-date-picker
                    style="width: 100%"
                    v-model="loan_date"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="setDateRange"
                    @change="onLoanDateChange"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结清时间：">
                  <el-date-picker
                    style="width: 100%"
                    v-model="settle_date"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="setDateRange"
                    @change="onSettleDateChange"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="审核状态:">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.approveStatus"
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in auditList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="商家名称：">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.businessName"
                    :remote-method="businessRemoteMethod"
                    :loading="businessLoading"
                    placeholder="请输入关键词"
                    clearable
                    filterable
                    remote
                    @clear="onBusinessClear"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in businessList"
                      :key="item.full_name"
                      :label="item.full_name"
                      :value="item.full_name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="账单状态：">
                  <el-select
                    style="width: 100%"
                    v-model="status"
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in orderStateList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="身份证照片：">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.isIdCardImg"
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in isIdCardImgList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="绑定银行卡：">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.isBindCard"
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in isBindCardList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="退费时间：">
                  <el-date-picker
                    style="width: 100%"
                    v-model="refund_date"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="setDateRange"
                    @change="onRefundDateChange"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="备注：">
                  <el-input
                    v-model="searchForm.remark"
                    placeholder="请输入备注"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <div style="text-align:right;">
                  <el-button style="margin-right:20px;"
                    size="small"
                    v-if="managerType != 0 || getAuth('exportOrder')"
                    @click="onExport"
                    >导出</el-button
                  >
                  <el-button type="primary" size="small" @click="serchData"
                    >搜索</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
    <base-table
      :columns="allOrder"
      :data="tableData"
      :pagination="searchForm"
      :total="total"
      @getData="initData"
      :loading="loading"
      :isPaginationShow="true"
      wrapperHeight="calc(100% - 45px)"
    >
      <el-table-column slot="reality_repay" label="授信金额" align="center">
        <template slot-scope="scope">
          {{ Math.floor(scope.row.reality_repayment_principal) }}
        </template>
      </el-table-column>
      <el-table-column
        slot="stage_type"
        label="产品类型"
        align="center"
        :formatter="stageTypeFormatter"
      ></el-table-column>
      <el-table-column
        slot="approve_status"
        label="审核状态"
        width="90"
        align="center"
        :formatter="approveStatusFormatter"
      ></el-table-column>
      <el-table-column
        slot="bill_status"
        label="订单状态"
        width="80"
        align="center"
        :formatter="billStatusFormatter"
      ></el-table-column>
      <el-table-column
        slot="is_id_card_img"
        label="上传身份证照片"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_id_card_img == 0">否</span>
          <!-- <span v-if="scope.row.is_id_card_img == 1">是</span> -->
        </template>
      </el-table-column>
      <el-table-column
        slot="is_bind_card"
        label="是否绑定银行卡"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_bind_card == 0">否</span>
          <!-- <span v-if="scope.row.is_bind_card == 1">是</span> -->
        </template>
      </el-table-column>
      <el-table-column slot="remark" label="备注" align="center">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>
      <el-table-column
        slot="operation"
        label="操作"
        align="center"
        fixed="right"
        v-if="managerType != 0 || getAuth('orderDetail')"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="checkDetail(scope.row)"
            >查看</el-button
          >
          <el-button type="text" size="small" @click="exportDetail(scope.row)"
            >预览并导出</el-button
          >
          <el-button type="text" size="small" v-if="scope.row.contract_image != ''" @click="exportAgreement(scope.row)"
            >课程协议和赊销协议</el-button
          >
        </template>
      </el-table-column>
    </base-table>
  </div>
</template>

<script>
import baseTable from "../../components/base-table/base-table.vue";
import columns from "./columns";
import { listBill, infoBill } from "@/api/bill.js";
import { infoEntity } from '@/api/entity.js'
import { selectBusiness } from "@/api/selectfun.js";
import { getAuth } from "@/utils/index.js";
import config from "@/utils/config.js";
import { createSeal, createName } from '../../utils/canvas_help.js'
import { exportBadSupplier } from '../../utils/word_help.js'
export default {
  components: { baseTable },
  name: "all",
  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      tableData: [],
      total: 0,

      status: 0,
      searchForm: {
        page: 1,
        per_page: 10,
        approveStatus: -1,
        billStatus: -1,
        isIdCardImg: -1,
        isBindCard: -1,
        isRepayment: -1,
        overdueStatus: -1,
      },
      isBindCardList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "未绑定银行卡",
        },
        {
          value: 1,
          label: "已绑定银行卡",
        },
      ],
      isIdCardImgList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "未上传身份证",
        },
        {
          value: 1,
          label: "已上传身份证",
        },
      ],
      auditList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "审核中",
        },
        {
          value: 1,
          label: "已审核",
        },
        {
          value: 2,
          label: "审核不通过",
        },
      ],
      loading: false,
      application_date: "",
      loan_date: "",
      refund_date: "",
      settle_date: "",
      businessList: [],
      businessLoading: false,
      thirdlyOrganizationList: [],
      productTypeList: [],
      orderStateList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "未生效",
        },
        {
          value: 2,
          label: "生效中",
        },
        {
          value: 3,
          label: "还款中",
        },
        {
          value: 4,
          label: "已逾期",
        },
        {
          value: 5,
          label: "账单完结",
        },
        {
          value: 6,
          label: "账单关闭",
        },
        {
          value: 7,
          label: "账单退费",
        },
      ],
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    onExport() {
      this.formatStatusParams()

      const url = `${
        config.BASE_URL
      }/export/billList?token=${localStorage.getItem("token")}&billNo=${
        this.searchForm.billNo || ""
      }&businessName=${this.searchForm.businessName || ""}&username=${
        this.searchForm.username || ""
      }&mobile=${this.searchForm.mobile || ""}&idCardNo=${
        this.searchForm.idCardNo || ""
      }&applyStartTime=${this.searchForm.applyStartTime || ""}&applyEndTime=${
        this.searchForm.applyEndTime || ""
      }&approveStartTime=${
        this.searchForm.approveStartTime || ""
      }&approveEndTime=${
        this.searchForm.approveEndTime || ""
      }&settleStartTime=${
        this.searchForm.settleStartTime || ""
      }&settleEndTime=${
        this.searchForm.settleEndTime || ""
      }&approveStatus=${
        this.searchForm.approveStatus
      }&billStatus=${
        this.searchForm.billStatus
      }&isRepayment=${
        this.searchForm.isRepayment
      }&overdueStatus=${
        this.searchForm.overdueStatus
      }&isIdCardImg=${
        this.searchForm.isIdCardImg
      }&isBindCard=${this.searchForm.isBindCard}`;
      window.open(url, "_blank");
    },
    async initData() {
      this.formatStatusParams()
      const { code, data } = await listBill(this.searchForm);
      if (code == 200) {
        this.tableData = data.data.map((item) => {
          if ((item.remark || "").length > 20) {
            item.remark = item.remark.substring(0, 18) + "...";
          }
          return item;
        });
        this.total = data.total;
      }
    },
    checkDetail(row) {
      const routeData = this.$router.resolve({
        path: "/order/detail",
        query: {
          // bill_id: row.bill_id,
          bill_no: row.bill_no,
        },
      });
      window.open(routeData.href, "_blank");
    },
    prewviewDetail(row) {
      const routeData = this.$router.resolve({
        path: "/order/prewviewDetail",
          query: {
            // bill_id: row.bill_id,
            data: encodeURIComponent(JSON.stringify(row)),
          },
      });
      window.open(routeData.href, "_blank");
      window.location.reload();
    },
    exportAgreement(row) {
      const routeData = this.$router.resolve({
        path: "/order/prewviewAgreement",
          query: {
            bill_id: row.bill_no,
            name: row.contract_layer_name,
            data: row.contract_image,
          },
      });
      window.open(routeData.href, "_blank");
      window.location.reload();
    },
    async exportDetail(row) {
      const {code, data} = await infoEntity({
        entity_id: row.entity_id
      })
      if (code == 200) {
        row.entity_address = data.business_address_province_name + data.business_address_city_name + data.business_address_district_name + data.business_address
        row.entity_phone = data.contact_user_phone
        row.entity_email = data.contact_user_email
        this.askUser(row)
      }
    },
    async askUser(row) {
      const param = {
        bill_no: row.bill_no,
      };
      const { code, data } = await infoBill(param);
      if (code == 200) {
        createSeal('canvas1', row.contract_layer_name, '')
        createName('canvas2', row.real_name)
        row.user_address = data.billInfo.residence_address
        row.list = data.billStageList
        row.start = data.billStageList[0].repayment_date
        row.end = data.billStageList[row.total_stage_count - 1].repayment_date
        console.log(row.end)
        row.id_card_no = data.billInfo.id_card_no
        row.product_name = data.billInfo.product_name
        row.canvas1 = document.getElementById("canvas1").toDataURL("image/png")
        row.canvas2 = document.getElementById("canvas2").toDataURL("image/png")
        this.prewviewDetail(row)
        if (row)
        return
        setTimeout(() => {
            row.canvas1 = document.getElementById("canvas1").toDataURL("image/png")
            row.canvas2 = document.getElementById("canvas2").toDataURL("image/png")
            exportBadSupplier(row, `${data.billInfo.real_name}的账单.docx`)
              .then(() => {
              console.log('导出成功')
            })
        }, 2000)
      }
    },
    onApplicationDateChange(val) {
      if (val) {
        this.searchForm.applyStartTime = val[0] + " " + "00:00:00";
        this.searchForm.applyEndTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.applyStartTime = "";
        this.searchForm.applyEndTime = "";
      }
    },
    onLoanDateChange(val) {
      if (val) {
        this.searchForm.approveStartTime = val[0] + " " + "00:00:00";
        this.searchForm.approveEndTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.approveStartTime = "";
        this.searchForm.approveEndTime = "";
      }
    },
    onSettleDateChange(val) {
      if (val) {
        this.searchForm.settleStartTime = val[0] + " " + "00:00:00";
        this.searchForm.settleEndTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.settleStartTime = "";
        this.searchForm.settleEndTime = "";
      }
    },
    onRefundDateChange(val) {
      if (val) {
        this.searchForm.refundStartTime = val[0] + " " + "00:00:00";
        this.searchForm.refundEndTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.refundStartTime = "";
        this.searchForm.refundEndTime = "";
      }
    },
    businessRemoteMethod(query) {
      if (query != "") {
        this.businessLoading = true;
        setTimeout(() => {
          this.businessLoading = false;
          this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = [];
      }
    },
    onBusinessClear() {
      this.searchForm.businessName = "";
      this.businessList = [];
      this.initData();
    },
    async getSelectBusiness(query) {
      const param = {
        business_name: query,
      };
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data;
      }
    },
    stageTypeFormatter(row) {
      switch (row.stage_type) {
        case 0:
          return "全款";
        case 1:
          return "等额还款";
        case 2:
          return "组合还款";
        case 3: 
          return "快捷支付";
        case "0":
          return "全款";
        case "1":
          return "等额还款";
        case "2":
          return "组合还款";
        case "3": 
          return "快捷支付";
      }
    },
    approveStatusFormatter(row) {
      switch (row.approve_status) {
        case 0:
          return "未审核";
        case 1:
          return "已审核";
        case 2:
          return "审核不通过";
        case "0":
          return "未审核";
        case "1":
          return "已审核";
        case "2":
          return "审核不通过";
      }
    },
    billStatusFormatter(row) {
      switch (row.bill_status) {
        case 0:
          return "未生效";
        case 1:
          if(row.is_overdue == 1){
            if(row.is_repayment != 1){
              return row.stage_type != 3 ? "已逾期" : "未支付";
            }
          }
          if(row.is_repayment == 1){
            return "还款中";
          }
          return "生效中";
        case 2:
          return row.stage_type != 3 ?  "账单完结" : "已支付";
        case 3:
          return "账单关闭";
        case 4:
          return "账单退费";
      }
    },
    formatStatusParams(){
      this.searchForm.isRepayment = -1
      this.searchForm.overdueStatus = -1
      if(this.status == 0){
        this.searchForm.billStatus = -1
      }
      if(this.status == 1){
        this.searchForm.billStatus = 0
      }
      if(this.status == 2){
        this.searchForm.billStatus = 1
      }
      if(this.status == 3){ // 还款中 = 生效中&还款&未逾期
        this.searchForm.billStatus = 1
        this.searchForm.isRepayment = 1
        this.searchForm.overdueStatus = 0
      }
      if(this.status == 4){ // 已逾期 = 生效中&逾期
        this.searchForm.billStatus = 1
        this.searchForm.overdueStatus = 1
      }
      if(this.status == 5){
        this.searchForm.billStatus = 2
      }
      if(this.status == 6){
        this.searchForm.billStatus = 3
      }
      if(this.status == 7){
        this.searchForm.billStatus = 4
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.all {
  .derive {
    margin-bottom: 10px;
  }
}
</style>