var maxLength = 15;
export function createSeal(id,company,name) {
    
    var canvas = document.getElementById(id);  
    var context = canvas.getContext('2d');
    
    // 绘制印章边框   
    var width=canvas.width/2;
    var height=canvas.height/2;
    context.clearRect(0, 0, canvas.width, canvas.height)
    context.lineWidth=4;//边框宽度
    context.strokeStyle="#f00";//边框颜色
    context.beginPath();
    context.arc(width,height,110,0,Math.PI*2);
    context.stroke();

    //画五角星
    create5star(context,width,height,30,"#f00",0);

    // 绘制印章名称   
    context.font = '22px Helvetica';
    context.textBaseline = 'middle';//设置文本的垂直对齐方式
    context.textAlign = 'center'; //设置文本的水平对对齐方式
    context.lineWidth=1;
    context.fillStyle = '#f00';
    context.fillText(name,width,height+65);

    // 绘制印章单位   
    context.translate(width,height);// 平移到此位置,
    context.font = '26px Helvetica'//字号
    var count = company.length;// 字数   
    var angle = (4*Math.PI/(3*(maxLength- 1)))+0.02;// 字间角度   字间距 +0.02~0.04
    var chars = company.split("");   
    var c;
    for (var i = 0; i < count; i++){
        c = chars[i];// 需要绘制的字符   
        if(i==0)
            //context.rotate(5*Math.PI/6);
            context.rotate( 3*Math.PI/2-angle*(count-1)/2 );//第一个字的位置
        else
        context.rotate(angle);
        context.save(); 
        context.translate(90, 0);// 平移到此位置,此时字和x轴垂直   
        context.rotate(Math.PI/2);// 旋转90度,让字平行于x轴   
        context.fillText(c,0, 5);// 此点为字的中心点   
        context.restore();             
    }

    //绘制五角星  
    /** 
     * 创建一个五角星形状. 该五角星的中心坐标为(sx,sy),中心到顶点的距离为radius,rotate=0时一个顶点在对称轴上 
     * rotate:绕对称轴旋转rotate弧度 
     */  
    function create5star(context,sx,sy,radius,color,rotato){
        context.save();  
        context.fillStyle=color;  
        context.translate(sx,sy);//移动坐标原点  
        context.rotate(Math.PI+rotato);//旋转  
        context.beginPath();//创建路径  
        var x = Math.sin(0);  
        var y= Math.cos(0);  
        var dig = Math.PI/5 *4;  
        for(var i = 0;i< 5;i++){//画五角星的五条边  
            x = Math.sin(i*dig);  
            y = Math.cos(i*dig);  
         context.lineTo(x*radius,y*radius);  
        }   
        context.closePath();  
        context.stroke();  
        context.fill();  
        context.restore();  
    }
}

export function createName(id,name) {
    var canvas = document.getElementById(id);  
    var context = canvas.getContext('2d');
    
   // 绘制边框   
   var height=canvas.height/2;
   context.clearRect(0, 0, canvas.width, canvas.height)
    context.lineWidth=4;//边框宽度
    context.strokeStyle="#f00";//边框颜色
    context.beginPath();
    context.strokeRect(0, height / 8 + 15, canvas.width - 20, height / 2);
    context.stroke();
    context.closePath()

    // 绘制名称   
    context.font = '30px Helvetica';
    context.textBaseline = 'middle';//设置文本的垂直对齐方式
    context.textAlign = 'center'; //设置文本的水平对对齐方式
    context.lineWidth=2;
    context.fillStyle = '#f00';
    context.fillText(name, canvas.width /2 - 15, height /2);
}

export function createNameWithTime(id,name, time) {
    var canvas = document.getElementById(id);  
    var context = canvas.getContext('2d');
    
   // 绘制边框   
   var height=canvas.height/2;
   context.clearRect(0, 0, canvas.width, canvas.height)
    context.lineWidth=4;//边框宽度
    context.strokeStyle="#f00";//边框颜色
    context.beginPath();
    context.strokeRect(0, height / 8 + 15, canvas.width - 20, height / 2);
    context.stroke();
    context.closePath()

    // 绘制名称   
    context.font = '30px Helvetica';
    context.textBaseline = 'middle';//设置文本的垂直对齐方式
    context.textAlign = 'center'; //设置文本的水平对对齐方式
    context.lineWidth=2;
    context.fillStyle = '#f00';
    context.fillText(name, canvas.width /2 - 15, height /2);

    // 绘制名称签字和时间
    context.font = '30px Helvetica';
    context.textBaseline = 'middle';//设置文本的垂直对齐方式
    context.textAlign = 'center'; //设置文本的水平对对齐方式
    context.lineWidth=2;
    context.fillStyle = '#000';
    context.fillText(name, 40, 50);
    context.fillText(time, 80, 150);
}

export function createSealWithNameAndTime(id,company,name,time) {
    var canvas = document.getElementById(id);  
    var context = canvas.getContext('2d');
    
    // 绘制印章边框   
    var width=canvas.width/2;
    var height=canvas.height/2;
    context.clearRect(0, 0, canvas.width, canvas.height)
    context.lineWidth=4;//边框宽度
    context.strokeStyle="#f00";//边框颜色
    context.beginPath();
    context.arc(width,height,110,0,Math.PI*2);
    context.stroke();

    //画五角星
    create5star(context,width,height,30,"#f00",0);

    // 绘制印章名称
    context.font = '26px Helvetica';
    context.textBaseline = 'middle';//设置文本的垂直对齐方式
    context.lineWidth=1;
    context.fillStyle = '#000';
    context.fillText(name,0,50);

    context.fillText(time,0,150);

    // 绘制印章单位   
    context.translate(width,height);// 平移到此位置,
    context.fillStyle = '#f00';
    context.font = '26px Helvetica'//字号
    var count = company.length;// 字数   
    var angle = (4*Math.PI/(3*(maxLength- 1)))+0.02;// 字间角度   字间距 +0.02~0.04
    var chars = company.split("");   
    var c;
    for (var i = 0; i < count; i++){
        c = chars[i];// 需要绘制的字符   
        if(i==0)
            //context.rotate(5*Math.PI/6);
            context.rotate( 3*Math.PI/2-angle*(count-1)/2 );//第一个字的位置
        else
        context.rotate(angle);
        context.save(); 
        context.translate(90, 0);// 平移到此位置,此时字和x轴垂直   
        context.rotate(Math.PI/2);// 旋转90度,让字平行于x轴   
        context.fillText(c,0, 5);// 此点为字的中心点   
        context.restore();             
    }

    

    //绘制五角星  
    /** 
     * 创建一个五角星形状. 该五角星的中心坐标为(sx,sy),中心到顶点的距离为radius,rotate=0时一个顶点在对称轴上 
     * rotate:绕对称轴旋转rotate弧度 
     */  
    function create5star(context,sx,sy,radius,color,rotato){
        context.save();  
        context.fillStyle=color;  
        context.translate(sx,sy);//移动坐标原点  
        context.rotate(Math.PI+rotato);//旋转  
        context.beginPath();//创建路径  
        var x = Math.sin(0);  
        var y= Math.cos(0);  
        var dig = Math.PI/5 *4;  
        for(var i = 0;i< 5;i++){//画五角星的五条边  
            x = Math.sin(i*dig);  
            y = Math.cos(i*dig);  
         context.lineTo(x*radius,y*radius);  
        }   
        context.closePath();  
        context.stroke();  
        context.fill();  
        context.restore();  
    }
}
