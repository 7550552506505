import docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
let docxsrc = 'temp_word.docx'
export function exportBadSupplier(data, docxname) {

    var ImageModule = require('docxtemplater-image-module-free');

    const expressions = require("angular-expressions");

    return new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(docxsrc, function (error, content) {
            // 抛出异常
            if (error) {
                reject(error)
                console.log(error)
                throw error;
            }
            expressions.filters.size = function (input, width, height) {
                return {
                    data: input,
                    size: [width, height],
                };
            };

            // 图片处理
            let opts = {}

            opts = {
                // 图像是否居中
                centered: false
            };
            opts.fileType = "docx";
            opts.getImage = (chartId) => {
                // console.log(chartId);//base64数据
                // 将base64的数据转为ArrayBuffer
                return base64DataURLToArrayBuffer(chartId);
            }

            opts.getSize = function (img, tagValue, tagName) {
                console.log(img);//ArrayBuffer数据
                console.log(tagValue);//base64数据
                console.log("tagName\n",tagName);//wordData对象的图像属性名
                // 自定义指定图像大小
                return [150, 150];
            }


            let zip = new PizZip(content);
            let doc = new docxtemplater().loadZip(zip);
            doc.attachModule(new ImageModule(opts));
            doc.loadZip(zip);
            doc.setData({
                ...data,
            });
            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                console.log(JSON.stringify({
                    error: e
                }));
                reject(e)
                throw error;
            }
            let out = doc.getZip().generate({
                type: "blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            })
            console.log(out)
            saveAs(out, docxname);
            resolve()
        })
    })
}

function base64DataURLToArrayBuffer(dataURL) {
    const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
    if (!base64Regex.test(dataURL)) {
        return false;
    }
    const stringBase64 = dataURL.replace(base64Regex, "");
    let binaryString;
    if (typeof window !== "undefined") {
        binaryString = window.atob(stringBase64);
    } else {
        binaryString = new Buffer(stringBase64, "base64").toString("binary");
    }
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes.buffer;
}